<template>
  <div v-if="order">

    <b-card-text><b>Tipo de producto:</b> {{ order.product_type.name }}</b-card-text>
    <b-row>
      <b-col
        v-if="order.destination"
        cols="12"
        xl="6"
      >
        <h6 class="mb-2">
          Cliente:
        </h6>
        <h6 class="mb-25">
          {{ order.destination.name }}
        </h6>
        <p class="card-text mb-25">
          {{ order.destination.contact_name }}
        </p>
        <p class="card-text mb-25">
          {{ order.destination.address }}, {{ order.destination.city }}
        </p>
        <p class="card-text mb-25">
          <b>Celular:</b> {{ order.destination.contact_phone }}
        </p>
      </b-col>

      <b-col md="4">
        <h4 class="invoice-title">
          Pedido
          <span class="invoice-number"> GLP-ZFR-{{ order.id }}</span>
        </h4>
        <div class="invoice-date-wrapper">
          <p class="invoice-date-title">
            <b>Estado: </b>{{ order.order_status.name }}
          </p>
        </div>
        <div class="invoice-date-wrapper">
          <p class="invoice-date-title">
            <b>Fecha:</b> {{ formatDateVariant(order.created_at) }}
          </p>
        </div>
      </b-col>
      
    </b-row>
    
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BCardText, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { required, } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {constants} from '@/helpers/constants'
import moment from 'moment';
import {formatDate} from "@/helpers/utils"

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardText,
    BSpinner,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      serial: '',
      sim: '',
      required,
      stowage: null,
      totalProducts: null,
      loading: false,
      order: null,
    }
  },
  props: {
    orderId: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const countryOptions = [
      { label: 'Colombia', value: 1 },
    ]

    return {
      countryOptions,
    }
  },
  computed: {
    formatDateVariant() {
      return date => formatDate(date)
    },
  },
  created() {
    this.getOrder()
  },
  methods: {

    getOrder() {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/get-order-detail/' + this.orderId, { headers }).then(response => {
        console.log('order: ', response)

        this.order = response.data
        
      }).catch( err => {
        console.log(err)
      })
    },
    
  
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
