<template>
  <div v-if="box">

    <b-card-text><b>Tipo de producto:</b> {{ box.stowage.product_type.name }}</b-card-text>
    <b-row>

      <b-col md="4">
        <div class="invoice-date-wrapper">
          <p class="invoice-date-title">
            <b>Caja No.: </b>{{ box.box_number }}
          </p>
        </div>
        <div class="invoice-date-wrapper">
          <p class="invoice-date-title">
            <b>Fecha:</b> {{ formatDateVariant(box.created_at) }}
          </p>
        </div>
      </b-col>
      
    </b-row>
    
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BCardText, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { required, } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {constants} from '@/helpers/constants'
import moment from 'moment';
import {formatDate} from "@/helpers/utils"

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardText,
    BSpinner,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      serial: '',
      sim: '',
      required,
      stowage: null,
      totalProducts: null,
      loading: false,
      box: null,
    }
  },
  props: {
    boxId: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const countryOptions = [
      { label: 'Colombia', value: 1 },
    ]

    return {
      countryOptions,
    }
  },
  computed: {
    formatDateVariant() {
      return date => formatDate(date)
    },
  },
  created() {
    this.getBox()
  },
  methods: {

    getBox() {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/boxes/' + this.boxId, { headers }).then(response => {
        console.log('boxes: ', response)

        this.box = response.data
        
      }).catch( err => {
        console.log(err)
      })
    },
    
  
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
