<template>
  <div v-if="product">

    <b-card-text><b>Tipo de producto:</b> {{ product.product_type.name }}</b-card-text>
    <b-row>
      <b-col
        cols="12"
        xl="6"
      >
        <h6 class="mb-2">
          Serial: {{ product.serial }}
        </h6>
        <h6 class="mb-2">
          <b>Fecha:</b> {{ formatDateVariant(product.created_at) }}
        </h6>

      </b-col>
      
    </b-row>
    
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BCardText, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { required, } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {constants} from '@/helpers/constants'
import moment from 'moment';
import {formatDate} from "@/helpers/utils"

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardText,
    BSpinner,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      serial: '',
      sim: '',
      required,
      stowage: null,
      totalProducts: null,
      loading: false,
      product: null,
    }
  },
  props: {
    productId: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const countryOptions = [
      { label: 'Colombia', value: 1 },
    ]

    return {
      countryOptions,
    }
  },
  computed: {
    formatDateVariant() {
      return date => formatDate(date)
    },
  },
  created() {
    this.getOrder()
  },
  methods: {

    getOrder() {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/products/' + this.productId, { headers }).then(response => {
        console.log('products: ', response)

        this.product = response.data
        
      }).catch( err => {
        console.log(err)
      })
    },
    
  
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
