<template>
  <b-card no-body>
    <b-card-header>
      <div class="d-flex align-items-center">
        <feather-icon
          size="24"
          class="mr-1"
          icon="ListIcon"
        />
        <b-card-title class="ml-25">
          Trazabilidad
        </b-card-title>
      </div>
    </b-card-header>

    <!-- timeline -->
    <b-card-body>
      <app-timeline v-if="logs && logs.logs.length > 0">

        <app-timeline-item 
          v-for="(log, index) in logs.logs"
          :key="index"
          :variant="log.action == 'created' ? 'success' : 'info'">
          <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
            <h6 :class="log.action == 'created' ? 'text-success' : 'text-info'">{{ log.action }}</h6>
            <small class="timeline-item-time text-nowrap text-muted ml-1">{{ formatDateVariant(log.created_at) }}</small>
          </div>

          <b-media v-if="log.user" no-body>
            <b-media-body class="my-auto">
              <h6 class="mb-0">
                {{ log.user.full_name }}
              </h6>
              <p class="mb-0">
                {{ log.user.profile.name }}
              </p>
            </b-media-body>
          </b-media>

          <br>

          <div v-if="log.changes" class="row">
            <div class="col-6 ml-2">
              <h6>Cambios</h6>
              <p  class="mb-0" v-for="(change, index) in JSON.parse(log.changes)"
                :key="index">
                {{index + ': ' + change}}
              </p>
            </div>
          </div>
          
        </app-timeline-item>

      </app-timeline>
      
      <h3 v-else class="text-warning mt-2 ml-2"> Sin datos de trazabilidad</h3>
      <!--/ timeline -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BImg, BMedia, BMediaBody, BMediaAside, BAvatar, BAvatarGroup, VBTooltip,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import axios from 'axios'
import {constants} from "@/helpers/constants"
import {formatDate} from "@/helpers/utils"

/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardHeader,
    BCardTitle,
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      logs: null,
      avatars: [
        { userImg: require('@/assets/images/portrait/small/avatar-s-9.jpg'), name: 'Billy Hopkins' },
        { userImg: require('@/assets/images/portrait/small/avatar-s-6.jpg'), name: 'Amy Carson' },
        { userImg: require('@/assets/images/portrait/small/avatar-s-8.jpg'), name: 'Brandon Miles' },
        { userImg: require('@/assets/images/portrait/small/avatar-s-7.jpg'), name: 'Daisy Weber' },
        { userImg: require('@/assets/images/portrait/small/avatar-s-20.jpg'), name: 'Jenny Looper' },
      ],
    }
  },
  props: {
    loggableType: {
      type: String,
      required: false,
    },
    loggableId: {
      type: String,
      required: false,
    },
  },
  computed: {
    formatDateVariant() {
      return date => formatDate(date)
    },
  },
  created() {
    this.getTraceability()
  },
  methods: {
    getTraceability() {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/get-traceability/' + this.loggableType + '/' + this.loggableId, { headers }).then(response => {
        console.log('get-traceability: ', response)

        this.logs = response.data.logs
        
      }).catch( err => {
        console.log(err)
      })
    },
  },
}
</script>
