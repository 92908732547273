<template>

  <div>

    <b-card 
      :title="'Datos de ' + loggableType">

      <b-row>
        <b-col cols="12">

          <product-detail-card v-if="loggableType == 'producto'" :product-id="loggableId"/>
          <box-detail-card v-if="loggableType == 'caja'" :box-id="loggableId"/>
          <stowage-detail-card v-if="loggableType == 'estiba'" :stowage-id="loggableId"/>
          <order-detail-card v-if="loggableType == 'pedido'" :order-id="loggableId"/>
          <product-newness-detail-card v-if="loggableType == 'novedad-producto'" :product-newness-id="loggableId"/>

        </b-col>
      </b-row>

    </b-card>

    <b-card 
      :title="'Trazabilidad de ' + loggableType">

      <b-row>
        <b-col cols="12">

          <traceability-card :loggable-type="loggableType" :loggable-id="this.loggableId"/>

        </b-col>
      </b-row>

    </b-card>

  </div>

</template>

<script>
import router from '@/router'
import { BRow, BCol, BTabs, BTab, BCard } from 'bootstrap-vue'
import TraceabilityCard from './TraceabilityCard.vue';
import OrderDetailCard from './OrderDetailCard.vue';
import ProductDetailCard from './ProductDetailCard.vue';
import ProductNewnessDetailCard from './ProductNewnessDetailCard.vue';
import BoxDetailCard from './BoxDetailCard.vue';
import StowageDetailCard from './../stowages/detail/StowageDetailCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,

    TraceabilityCard,
    OrderDetailCard,
    ProductDetailCard,
    StowageDetailCard,
    BoxDetailCard,
    ProductNewnessDetailCard,
  },
  data() {
    return {
      loggableType: null,
    }
  },
  created() {
    this.loggableType = router.currentRoute.params.loggable_type
    this.loggableId = router.currentRoute.params.loggable_id
    console.log(this.loggableType, this.loggableId)
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>